<template>
  <div>
    <van-field
      readonly
      clickable
      :label="label"
      :value="nameValue"
      :placeholder="placeholder"
      @click="showPicker = true"
    />

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'field-picker',
  props: {
    value: null,
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    options: {
      type: Array
    }
  },
  data () {
    return {
      showPicker: false,
      indexValue: null,
      nameValue: this.value,
      idValue: this.value
    }
  },
  computed: {
    columns () {
      return this.options.map((option) => {
        return option.name
      })
    }
  },
  methods: {
    onConfirm (value, index) {
      this.indexValue = index
      this.showPicker = false
    }
  },
  watch: {
    indexValue (val) {
      if (val !== null) {
        this.idValue = this.options[val].id
        this.nameValue = this.options[val].name
      } else {
        this.idValue = null
        this.nameValue = null
      }

      this.$emit('input', this.idValue)
      this.$emit('change', this.idValue)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
