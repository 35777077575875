<template>
  <div>
    <van-field
      readonly
      clickable
      :label="label"
      :value="dateString"
      :placeholder="placeholder"
      @click="showPicker = true"
    />

    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="data"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="showPicker=false"
        @cancel="showPicker=false"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'field-picker',
  props: {
    value: {

    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    minDate: {
      type: Date
    },
    maxDate: {
      type: Date
    }
  },
  data () {
    return {
      showPicker: false,
      data: this.value,
      dateString: null
    }
  },
  computed: {
  },
  methods: {
    onConfirm (value, index) {
      this.indexValue = index
      this.showPicker = false
    }
  },
  watch: {
    data (val) {
      let str = moment(val).format('YYYY-MM-DD')
      this.dateString = str
      this.$emit('input', str)
      this.$emit('change', str)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
