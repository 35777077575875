<template>
  <div>
    <van-nav-bar title="在线报修" left-arrow  @click-left="$router.go(-1)" />

    <div class="content-padding repair">
      <div class="stit">1.选择设备信息</div>
      <div class="item">
        <div class="bq">*设备信息</div>
        <div class="cont">
          <field-picker v-model="record.category_id" placeholder="请选择产品分类" :options="categories" @change="loadProducts"></field-picker>
          <field-picker v-model="record.product_id"  placeholder="请选择产品" :options="products"></field-picker>
        </div>
      </div>
      <div class="stit">2.选择故障类型</div>
      <div class="item">
        <div class="bq">*故障类型</div>
        <div class="cont">
          <div class="btns">
             <van-button v-for="(item, key) in errorTypes" :class="{active: errArr.indexOf(item) > -1}"  :key="key"  @click="getErrorType(item)" type="default" size="small">{{item}}</van-button>
          </div>
          <van-field v-model="sys" type="textarea" class="errText" placeholder="请简单描述您的故障类型，如果是APP使用及软件类问题，建议您先联系微信公众号右下角-我的服务-在线客服获取指导" />
        </div>
      </div>
      <div class="item">
        <div class="bq">*故障图片</div>
        <div class="cont">
          <van-uploader v-model="files" :after-read="uploadFile" :max-count="3" multiple style="margin-left: .37333rem;" />
        </div>
      </div>
      <div class="stit">3.选择购买渠道</div>
      <div class="item">
        <div class="bq">*购买渠道</div>
        <div class="cont">
          <field-picker v-model="record.channel_id" placeholder="请选择购买渠道" :options="channels"></field-picker>
        </div>
      </div>
      <div class="item">
        <div class="bq"> 购买日期</div>
        <div class="cont">
          <field-date-picker v-model="record.buy_date" placeholder="请选择购买日期"></field-date-picker>
        </div>
      </div>
      <div class="item">
        <div class="bq">购买订单号</div>
        <div class="cont">
          <van-field v-model="record.order_no"  />
        </div>
      </div>
      <div class="stit">4.填写用户联系人信息</div>
      <div class="item">
        <div class="bq">*姓名</div>
        <div class="cont">
          <van-field v-model="record.client_name" />
        </div>
      </div>
      <div class="item">
        <div class="bq">*手机号</div>
        <div class="cont">
          <van-field  v-model="record.client_phone"  />
        </div>
      </div>
      <div class="item">
        <div class="bq">*手机验证码</div>
        <div class="cont">
          <van-field v-model="record.auth_code">
            <van-button slot="button" size="small" type="primary" @click="getAuthCode">{{ buttonText }}</van-button>
          </van-field>
        </div>
      </div>
      <div class="item">
        <div class="bq">*回寄地址</div>
        <div class="cont">
          <div class="adds">
            <field-picker v-model="address.province" class="areaSel" placeholder="-请选择省份" :options="provinces" @change="getProvince"></field-picker>
            <field-picker v-model="address.city" class="areaSel" placeholder="-请选择市" :options="cities" @change="getCity"></field-picker>
            <field-picker v-model="address.area" class="areaSel" placeholder="-请选择区/镇" :options="areas"></field-picker>
          </div>
          <van-field v-model="address.detail" type="textarea" class="errText address" placeholder="请填写详细的维修后返回的地址，精确到门牌号" />
        </div>
      </div>
      <!-- <van-field v-model="record.client_name" label="姓名" />
      <van-field v-model="record.client_phone" label="手机号" />
      <van-field v-model="record.client_address" label="地址" /> -->
      <!-- <van-field v-model="record.order_no" label="购买订单号" /> -->
      <div class="stit">5.提交报修</div>
      <div class="item">
        <div class="bq">*验证码</div>
        <div class="cont">
          <van-field v-model="record.captcha">
            <img slot="button" :src="captchaUrl + random" @click="random = randomCode()">
          </van-field>
        </div>
      </div>
      <van-button type="primary" class="submit" size="large" @click="login">提交</van-button>
      <p class="tip">注：<span>*</span> 为必填项</p>
      <p class="tip">申请售后服务前，请您提前备份个人数据。维修过程中可能会造成您个人数据丢失,华米售后不承担维修过程中个人数据损坏、丢失责任。</p>
      <p class="tip">如果您想了解更多的服务政策信息，<a href="/services/strategy">请点击</a></p>
    </div>
  </div>
</template>

<script>
import FieldPicker from '../../components/FieldPicker.vue'
import FieldDatePicker from '../../components/FieldDatePicker.vue'

export default {
  name: 'repair',
  components: {
    FieldPicker,
    FieldDatePicker
  },
  data () {
    return {
      record: {
        auth_code: ''
      },
      fileList: [],
      channels: [],
      categories: [],
      products: [],
      files: [],
      random: this.randomCode(),
      captchaUrl: 'http://sale.huami.com/v2/captchas/image?code=',
      provinces: [{ id: 0, name: '-请选择省份' }],
      cities: [{ id: 0, name: '-请选择市' }],
      areas: [{ id: 0, name: '-请选择区/镇' }],
      address: {
        province: '-请选择省份',
        city: '-请选择市',
        area: '-请选择区/镇',
        detail: ''
      },
      errorTypes: [
        '充电异常',
        '开关机异常',
        '屏幕显示异常',
        '待机时间短',
        '无法连接APP',
        '屏幕触摸异常',
        '系统卡死',
        '振动异常',
        '外壳损坏',
        '其他'
      ],
      errArr: [],
      sys: '',
      buttonText: '获取验证码'
    }
  },
  created () {
    this.loadCategories()
    this.loadChannels()
    this.getAddress()
    this.record.client_phone = localStorage.getItem('customer-phone') ? localStorage.getItem('customer-phone') : ''
  },
  methods: {
    randomCode () {
      return Math.random().toString().replace('0.', '')
    },
    loadChannels () {
      this.$http.get('/channels').then((res) => {
        this.channels = res.data
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    },
    loadCategories () {
      this.$http.get('/product-categories').then((res) => {
        this.categories = res.data
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    },
    loadProducts () {
      this.$http.get('/products', { params: { category_id: this.record.category_id } }).then((res) => {
        this.products = res.data
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    },
    uploadFile (file) {
      let config = { headers: { 'Content-Type': 'multipart/form-data' } }
      let formData = new FormData()
      // console.log('file', file)
      formData.append('file', file.file)
      // formData.append('file', file)
      this.$http.post('pmp-records/upload-file', formData, config).then((res) => {
        console.log(res)
        let data = res.data
        this.fileList.push({ id: data.id, url: URL.createObjectURL(file.file), isImage: true })
      }).catch((err) => {
        this.$toast.fail(err.response.data.message)
      })
    },
    checkField () {
      if (!this.record.category_id) {
        this.$toast.fail('请选择产品类型')
        return false
      }

      if (!this.record.product_id) {
        this.$toast.fail('请选择产品型号')
        return false
      }

      if (!this.errArr.length) {
        this.$toast.fail('请选择故障类型')
        return false
      }
      if (!this.record.channel_id) {
        this.$toast.fail('请选择购买渠道')
        return false
      }

      if (!this.record.client_name || !this.record.client_name.trim()) {
        this.$toast.fail('请填写姓名')
        return false
      }

      if (!this.record.client_phone || !this.record.client_phone.trim()) {
        this.$toast.fail('请填写手机号')
        return false
      }

      if (!this.record.auth_code || !this.record.auth_code.trim()) {
        this.$toast.fail('请填写手机验证码')
        return false
      }

      if (this.address.province === '-请选择省份') {
        this.$toast.fail('请选择省份')
        return false
      }

      if (this.address.city === '-请选择市') {
        this.$toast.fail('请选择市')
        return false
      }

      if (this.address.area === '-请选择区/镇') {
        this.$toast.fail('请选择区/镇')
        return false
      }

      if (!this.address.detail || !this.address.detail.trim()) {
        this.$toast.fail('请填写详细地址')
        return false
      }

      if (!this.record.captcha || !this.record.captcha.trim()) {
        this.$toast.fail('请填写验证码')
        return false
      }

      return true
    },
    login () {
      if (!this.checkField()) return

      this.$http.post('/customers/login', { phone: this.record.client_phone, code: this.record.auth_code }).then((res) => {
        localStorage.setItem('amafit-token', res.data.token)
        localStorage.setItem('customer-phone', this.record.client_phone)
        this.$http.defaults.headers.common['X-Access-Token'] = res.data.token
        // if (this.redirectUrl) {
        //   this.$router.replace(this.redirectUrl)
        // } else {
        //   this.$router.replace('/services/repair-list')
        // }
        this.submit()
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    },
    submit () {
      this.record.captcha_code = this.random
      this.record.file_id = this.fileList.map((item) => {
        return item.id
      })
      let { address, provinces, cities, areas } = this
      let s1 = provinces.find(item => item.id === address.province)
      let s2 = cities.find(item => item.id === address.city)
      let s3 = areas.find(item => item.id === address.area)
      this.record.client_address = s1.name + s2.name + s3.name + this.address.detail
      if (this.errArr.length > 0) {
        this.record.symptom = this.errArr.join('-') + '-' + this.sys
      } else {
        this.$toast.fail('请选择故障类型')
        return false
      }

      this.$http.post('pmp-records', this.record).then((res) => {
        this.$dialog.alert({
          message: '提交成功！请尽快按照短信提示将产品寄回'
        }).then(() => {
          this.$router.push('/services')
        })
      }).catch((err) => {
        this.$toast.fail(err.response.data.message)
      })
    },
    getAddress: function () {
      let that = this
      this.$http.get('regions').then(res => {
        that.provinces = that.provinces.concat(res.data)
      })
    },
    getProvince: function (e) {
      let id = e
      let path = 'regions/' + id + '/children'
      let that = this
      this.$http(path).then(res => {
        that.address.city = '-请选择市'
        that.address.area = '-请选择区/镇'
        that.cities = [{ id: 0, name: '-请选择市' }].concat(res.data)
      })
    },
    getCity: function (e) {
      let id = e
      let path = 'regions/' + id + '/children'
      let that = this
      this.$http.get(path).then(res => {
        that.address.area = '-请选择区/镇'
        that.areas = [{ id: 0, name: '-请选择区/镇' }].concat(res.data)
      })
    },
    getErrorType (str) {
      let arr = this.errArr
      if (arr.indexOf(str) > -1) {
        arr.splice(this.errArr.indexOf(str), 1)
      } else {
        arr.push(str)
      }
      this.errArr = arr
    },
    getAuthCode () {
      this.$http.post('https://sale.huami.com/v1/auth-codes', { phone: this.record.client_phone }).then((res) => {
        this.buttonText = 60
        this.timer = setInterval(() => {
          this.buttonText--

          if (this.buttonText === 0) {
            clearInterval(this.timer)
            this.buttonText = '获取验证码'
          }
        }, 1000)
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    }
  }
}
</script>
<style lang="scss">
.repair {
  padding: 2vw;
  .van-field {
    border-bottom: 1px solid #efefef;
  }
  .errText {
    border: 1px solid #efefef;
    margin-left: .37333rem;
    width: calc( 100% - .37333rem );
    box-sizing: border-box;
    font-size: 3vw;
    line-height: 1.4;
    &.address {
      margin-left: 0;
      width: 100%;
      margin-top: 3vw;
    }
  }
  .areaSel {
    padding-top: .27333rem;
    margin-right: 2vw;
    &:last-child {
      margin-right: 0;
    }
    .van-cell {
      font-size: 3vw;
      padding: 0;
    }
  }
}
.stit {
  font-size: 4vw;
  padding: 1vw 0;
  margin-bottom: 2vw;
  color: #000;
  font-weight: bold;
  margin-top: 3vw;
}
.item {
  display: flex;
  padding: 0 3vw;
  margin-bottom: 3vw;
  .bq {
    flex-shrink: 0;
    width: 24vw;
    font-size: .373333rem;
    padding-top: .266667rem;
    line-height: .64rem;
  }
  .cont {
    flex-grow: 1;
    .btns {
      display: flex;
      flex-wrap: wrap;
      padding: .1rem 0 0 .373333rem;
      button {
        margin:0 2vw 2vw 0;
        &.active {
          color: #fcc800;
          border: 1px solid #fcc800;
        }
      }
    }
    p {
      margin-left: .37333rem;
    }
    .adds {
      display: flex;
    }
  }
}
</style>
